import { useEffect, useState } from 'react';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { useTranslation } from 'react-i18next';

import Icon from 'components/atoms/icon';
import s from './QuantitySelector.module.scss';

export const QuantitySelector = ({ onChange, value, error }) => {
  const { t } = useTranslation();

  const dataQuantity = [
    `${t('Prototype')}`,
    25,
    50,
    100,
    150,
    250,
    500,
    1000,
    1500,
    2000,
    2500,
    5000,
  ];

  const [quantity, setQuantity] = useState(value);
  const [inputQuantityVisible, setInputQuantityVisible] = useState(false);

  const handlerSelect = e => {
    setQuantity(+e.target?.innerText);
    setInputQuantityVisible(false);
  };

  useEffect(() => {
    onChange(quantity);
  }, [quantity]);

  useEffect(() => {
    setQuantity(value);
  }, [value]);

  return (
    <div className={s.containerQuantity}>
      <p className={s.containerAccentText}>
        {t('Quantity')} <span className={s.containerAccentTextPink}>*</span>
      </p>
      <ClickAwayListener onClickAway={() => setInputQuantityVisible(false)}>
        <div className={s.wrapperInputText}>
          <input
            autoComplete="off"
            className={[
              s.inputText,
              inputQuantityVisible && s.inputTextMaterials, //! there is no such class in styles
            ].join(' ')}
            type="text"
            style={error ? { border: '1px solid red' } : {}}
            onChange={e => setQuantity(e.target.value)}
            value={quantity}
          />
          {!inputQuantityVisible && (
            <Icon
              size={11}
              name="down"
              onClick={() => setInputQuantityVisible(val => !val)}
              className={s.iconArrowQuantity}
            ></Icon>
          )}
          {inputQuantityVisible && (
            <Icon
              size={11}
              name="down"
              onClick={() => setInputQuantityVisible(val => !val)}
              className={s.iconArrowQuantityUp}
            ></Icon>
          )}
          {inputQuantityVisible && (
            <ul className={s.containerOption}>
              {dataQuantity.map(item => (
                <li
                  className={s.selectQuantity}
                  onClick={handlerSelect}
                  key={item}
                >
                  {item}
                </li>
              ))}
            </ul>
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
};
