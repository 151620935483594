import s from './team.module.scss';
import react, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import picture from '../../images/team/foto_modelo.jpg';
import picture2 from '../../images/team/peuker.jpg';
import picture4 from '../../images/team/production.jpg';
import person1 from '../../images/team/szasha.jpg';
import person2 from '../../images/team/stewen.jpg';
import person3 from '../../images/team/manuel-photo.jpg';
import person4 from '../../images/team/carmen.jpg';
import person5 from '../../images/team/güngör.jpg';
import Icon from 'components/atoms/icon';
import Title from 'components/molecules/title/Title';
const data = [
  {
    src: person1,
    resposibility1: 'Sales / Production',
    position: 'CEO',
    fio: 'Sascha Maluscik',
    icon: 'arrow',
    mail: 'sascha.maluscik@pfz-cnc.com',
    href: 'mailto: sascha.maluscik@pfz-cnc.com',
  },
  {
    src: person2,
    resposibility1: 'Production / Development',
    position: 'CEO',
    fio: 'Steven Maluscik',
    icon: 'arrow',
    mail: 'steven.maluscik@pfz-cnc.com',
    href: 'mailto: steven.maluscik@pfz-cnc.com',
  },
  {
    src: person3,
    resposibility1: 'Sales / Marketing',
    position: 'CEO',
    fio: 'Manuel Wörner',
    icon: 'arrow',
    mail: 'manuel.woerner@pfz-cnc.com',
    href: 'mailto: manuel.woerner@pfz-cnc.com',
  },
  {
    src: person4,
    resposibility1: 'Purchasing / Аccounting',
    fio: 'Carmen SPÄTLING',
    icon: 'arrow',
    mail: 'carmen.spaetling@pfz-cnc.com',
    href: 'mailto: carmen.spaetling@pfz-cnc.com',
  },
  {
    src: person5,
    resposibility1: 'Quality',
    fio: 'Güngör Uyanik',
    icon: 'arrow',
    mail: 'guengoer.uyanik@pfz-cnc.com',
    href: 'mailto: guengoer.uyanik@pfz-cnc.com',
  },
];

const source = {
  title: {
    desktop: {
      svg1: 'team-title1-2vector2',
      svg2: 'team-title1-2vector1',
      svg3: 'team-title1-2vector3',

      svg4: 'team-title3vector1',
      svg5: 'team-title3vector2',
      svg6: 'team-title3vector3',

      svg7: 'team-title4vector2',
      svg8: 'team-title4vector1',
      svg9: 'team-title4vector3',
    },
    tablet: {
      svg1: 'team-tablet-title1-2vector2',
      svg2: 'team-tablet-title1-2vector1',
      svg3: 'team-tablet-title1-2vector3',

      svg4: 'team-tablet-title4vector1',
      svg5: 'team-tablet-title4vector2',
      svg6: 'team-tablet-title4vector3',
    },
    mobile: {
      svg1: 'team-mobile-title1-2vector2',
      svg2: 'team-mobile-title1-2vector1',
      svg3: 'team-mobile-title1-2vector3',

      svg4: 'team-mobile-title4vector2',
      svg5: 'team-mobile-title4vector1',
      svg6: 'team-mobile-title4vector3',
    },
  },
};

const Team = () => {
  const { t, i18n } = useTranslation();

  const [isBlokVisible, setIsBlokVisible] = useState(false);
  useEffect(() => {
    if (isBlokVisible) {
      document.body.style.overflow = 'hidden';
    }
    if (!isBlokVisible) {
      document.body.style.overflow = 'visible';
    }
  }, [isBlokVisible]);

  return (
    <>
      {isBlokVisible && (
        <div
          className={s.backdrop}
          onClick={() => {
            setIsBlokVisible(false);
          }}
        >
          <Icon name="icon-close-menu" size={30} className={s.closeIcon} />
          <div className={s.backdropWrapper}>
            <div className={s.historyLeftPartDesktop}>
              <div className={s.picturesHistory}>
                <picture className={s.imgWrapper}>
                  <img src={picture2} alt="peuker" height="370" width="344" />
                </picture>
              </div>
              <div className={s.containerWrapperHistory}>
                <Title
                  svg1={source.title.desktop.svg5}
                  svg2={source.title.desktop.svg4}
                  svg3={source.title.desktop.svg6}
                  top1={56}
                  left1={-45}
                  top2={31}
                  animationDisabled={true}
                >
                  <p className={s.imgTextAccent}>{t('Helmut Peuker')}</p>
                </Title>
              </div>
            </div>
            <div className={s.historyLeftPartTablet}>
              <div className={s.picturesHistory}>
                <picture className={s.imgWrapper}>
                  <img src={picture2} alt="peuker" height="370" width="344" />
                </picture>
              </div>
              <div className={s.containerWrapperHistory}>
                <Title
                  svg1={source.title.tablet.svg5}
                  svg2={source.title.tablet.svg4}
                  svg3={source.title.tablet.svg6}
                  top1={24}
                  top2={37}
                  animationDisabled={true}
                >
                  <p className={s.imgTextAccent}>{t('Helmut Peuker')}</p>
                </Title>
              </div>
            </div>

            <div className={s.historyLeftPartMobile}>
              <div className={s.picturesHistory}>
                <picture className={s.imgWrapper}>
                  <img src={picture2} alt="peuker" height="240" width="223" />
                </picture>
              </div>
              <div className={s.containerWrapperHistory}>
                <Title
                  svg1={source.title.mobile.svg5}
                  svg2={source.title.mobile.svg4}
                  svg3={source.title.mobile.svg6}
                  top1={17}
                  top2={28}
                  top3={0}
                  left1={11}
                  animationDisabled={true}
                >
                  <h3 className={s.imgTextAccent}>{t('Helmut Peuker')}</h3>
                </Title>
              </div>
            </div>

            <p className={s.textLineBackdrop}>
              {t(
                'There is hardly any other company in the field of contract manufacturing that knows the strengths and weaknesses of the Chiron machining centers used by him as intensively as Helmut Peuker. Which should come as no surprise, however, because half of his professional life revolved around Chiron machines. Starting with a successful apprenticeship as a toolmaker, then nine years in fixture construction, he also completed his master craftsman at Chiron. When Chiron launched the first CNC-controlled machines on the market, Helmut Peuker worked as a CNC programmer for three years in the field to train customers and put the machines into operation. Helmut Peuker then received the offer to work as a project engineer in the project office. An undoubtedly interesting task, which he carried out for nine years. Nevertheless - and this only a few months before his 25 years of service, he decided to become self-employed and PFZ-Peuker was founded in the Brühlstraße in Möhringen.'
              )}
            </p>
          </div>
        </div>
      )}
      <div className={s.containerScroll}>
        <div
          className={i18n.language === 'de' ? s.container : s.containerEn}
        ></div>
        <div className={s.wrapper}>
          <div className={s.teamManagement}>
            <div id="team" className={s.teamLink}></div>
            <div className={s.containerWrapper}>
              <Title
                svg1={source.title.desktop.svg1}
                svg2={source.title.desktop.svg2}
                svg3={source.title.desktop.svg3}
              >
                <h2 className={s.h3}>{t('Our team')}</h2>
              </Title>
            </div>
            <div className={s.wrapperHistory}>
              <div
                className={
                  i18n.language === 'en' ? s.teamRightPart : s.teamRightPartDe
                }
              >
                <p className={s.imgText}>
                  {t('Teamwork - We take responsibility together')}
                </p>
                <div className={s.pictures}>
                  <picture className={s.imgWrapper}>
                    <img
                      className={s.images}
                      src={picture}
                      alt="Production"
                      height="254"
                      width="380"
                    />
                  </picture>
                </div>
                <div className={i18n.language === 'de' ? s.text : s.textEn}>
                  <p className={s.textLine}>
                    {t(
                      'Behind every business success is a great team. And PFZ GmbH & Co. KG is no exception. Many employees have been working for the company for more than 10 years. The team has a culture of "family feeling". Working with competent employees whom the company respects and trusts means that ideas and plans move further, faster and more effectively.'
                    )}
                  </p>
                  <div
                    className={i18n.language === 'de' ? s.line : s.lineEn}
                  ></div>
                </div>
              </div>
              <div className={s.teamLeftPart}>
                <p className={s.imgText}>{t('Production area')}</p>

                <div className={s.pictures}>
                  <picture className={s.imgWrapper}>
                    <img
                      className={s.images}
                      src={picture4}
                      alt="our team"
                      height="254"
                      width="380"
                    />
                  </picture>
                </div>

                <div className={i18n.language === 'de' ? s.text : s.textEn}>
                  <p className={s.textLine}>
                    {t(
                      'We specialize in milling, turning, and laser cutting on modern machining centers. In two-shift operations, we produce components for medical technology, mechanical engineering, aerospace, and the jewelry industry. We also machine materials that are unusually difficult to machine, such as titanium, platinum, corrosion-resistant steel or high-strength plastics.'
                    )}
                  </p>
                  <p className={s.textLine}>
                    {t(
                      'Small and medium series of 25 to 300 parts are our strength, but larger series can also be produced off the shelf on our machining center. The production of prototypes is one of our daily challenges.'
                    )}
                  </p>
                  <p className={s.textLine}>
                    {t(
                      'In order to achieve the productivity required for cost-effective production, we work exclusively on machining centers from Chiron.'
                    )}
                  </p>
                  <p className={s.textLine}>
                    {t(
                      'In order to ensure almost one hundred percent process reliability, our drilling and milling tools are automatically checked again and again for wear and breakage.'
                    )}
                  </p>
                  <div
                    className={
                      i18n.language === 'de' ? s.lineAccent : s.lineAccentEn
                    }
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div id="user" className={s.teamLink}></div>
          <div className={s.containerWrapper}>
            <Title
              svg1={source.title.desktop.svg1}
              svg2={source.title.desktop.svg2}
              svg3={source.title.desktop.svg3}
            >
              <h3 className={s.h3}>{t('Contacts')}</h3>
            </Title>
          </div>
          <div className={s.team}>
            <ul className={s.linkList}>
              {data.map(item => (
                <li className={s.item} key={item.src}>
                  <div className={s.containerInfo}>
                    <img
                      src={item.src}
                      alt="personPhoto"
                      height="256"
                      width="213"
                    />
                    <div className={s.containerPerson}>
                      <p className={s.fio}>{t(item.fio)}</p>
                      <p className={s.position}>{t(item.position)}</p>
                    </div>
                    <p className={s.resposibility}>{t(item.resposibility1)}</p>
                    {item.resposibility2 && (
                      <p className={s.resposibility2}>
                        {t(item.resposibility2)}
                      </p>
                    )}
                    <a
                      className={s.linkSocial}
                      href={item.href}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p className={s.mail}>{t(item.mail)}</p>
                    </a>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <div className={s.containerWrapper}>
              <div id="history" className={s.linkHistory}></div>
              <Title
                svg1={source.title.desktop.svg2}
                svg2={source.title.desktop.svg1}
                svg3={source.title.desktop.svg3}
                top1={56}
                top2={31}
              >
                <h2 className={s.h3}>{t('History')}</h2>
              </Title>
            </div>
            <div className={s.containerWrapperHistoryDesktop}>
              <div className={s.containerPosition}>
                <div className={s.pictures}>
                  <picture className={s.imgWrapper}>
                    <img
                      className={s.images}
                      src={picture}
                      alt="Production"
                      height="auto"
                      width="500"
                    />
                  </picture>
                </div>
                <div className={s.historyRightPart}>
                  <p className={s.textLineUppercaseAccent}>
                    {t('Company foundation in 2000:')}{' '}
                  </p>
                  <p
                    className={s.information}
                    onClick={() => {
                      setIsBlokVisible(!isBlokVisible);
                    }}
                  >
                    {t('more information')} -->
                  </p>

                  <p className={s.textLineUppercase}>
                    {t('Change of location in 2006:')}
                  </p>
                  <p className={s.textLine}>
                    {t(
                      'In the summer of 2006, the company moved to Messerschmittstraße 5 in the Möhringer Vorstadt. Since then, Peuker has been manufacturing with its highly qualified specialists in a production area of 500 square meters.'
                    )}
                    <br />
                  </p>

                  <p className={s.textLineUppercase}>
                    {t('Change of name in 2019:')}
                  </p>
                  <span className={s.textLineTop}>
                    {t(
                      'With the recording in the register, the sole proprietorship Peuker Fräs - und Zerspanungstechnik e.K. been transferred to'
                    )}
                  </span>
                  <span className={s.textLineAccent}>
                    {t('Peuker - und Zerspanungstechnik GmbH & Co. KG.')}
                  </span>

                  <p className={s.textLineUppercase}>
                    {t('Succession plan in 2022:')}
                  </p>
                  <p className={s.textLine}>
                    {t(
                      'In the course of the succession Helmut Peuker decided to hand over the company to experienced hands and PFZ was taken over by long-time existing employees in order to be able to continue to guarantee a smooth handling for our customers.'
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={s.containerScrollTablet}>
        <div
          className={
            i18n.language === 'de' ? s.containerTablet : s.containerTabletEn
          }
        ></div>

        <div className={s.wrapper}>
          <div className={s.teamManagement}>
            <div id="team_t" className={s.teamLink}></div>
            <div className={s.containerWrapper}>
              <Title
                svg1={source.title.tablet.svg2}
                svg2={source.title.tablet.svg1}
                svg3={source.title.tablet.svg3}
              >
                <h2 className={s.h3}>{t('Our team')}</h2>
              </Title>
            </div>
            <div className={s.wrapperHistory}>
              <div
                className={
                  i18n.language === 'en' ? s.teamRightPart : s.teamRightPartDe
                }
              >
                <p className={s.imgText}>
                  {t('Teamwork - We take responsibility together')}
                </p>
                <div className={s.pictures}>
                  <picture className={s.imgWrapper}>
                    <img
                      className={s.images}
                      src={picture}
                      alt="Production"
                      height="254"
                      width="380"
                    />
                  </picture>
                </div>
                <div className={s.text}>
                  <p className={s.textLine}>
                    {t(
                      'Behind every business success is a great team. And PFZ GmbH & Co. KG is no exception. Many employees have been working for the company for more than 10 years. The team has a culture of "family feeling". Working with competent employees whom the company respects and trusts means that ideas and plans move further, faster and more effectively.'
                    )}
                  </p>
                </div>
              </div>
              <div className={s.teamLeftPart}>
                <p className={s.imgText}>{t('Production area')}</p>
                <div className={s.pictures}>
                  <picture className={s.imgWrapper}>
                    <img
                      className={s.images}
                      src={picture4}
                      alt="our team"
                      height="234"
                      width="351"
                    />
                  </picture>
                </div>

                <div className={s.text}>
                  <p className={s.textLine}>
                    {t(
                      'We specialize in milling, turning, and laser cutting on modern machining centers. In two-shift operation we produce components for medicine, electrical engineering and mechanical engineering. We also machine materials that are unusually difficult to machine, such as titanium, platinum, corrosion-resistant steel or high-strength plastics.'
                    )}
                  </p>
                  <p className={s.textLine}>
                    {t(
                      'Small and medium series of 25 to 300 parts are our strength, but larger series can also be produced off the shelf on our machining center. The production of prototypes is one of our daily challenges.'
                    )}
                  </p>
                  <p className={s.textLine}>
                    {t(
                      'In order to achieve the productivity required for cost-effective production, we work exclusively on machining centers from Chiron.'
                    )}
                  </p>
                  <p className={s.textLine}>
                    {t(
                      'In order to ensure almost one hundred percent process reliability, our drilling and milling tools are automatically checked again and again for wear and breakage.'
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="user_t" className={s.teamLink}></div>
          <div className={s.containerWrapper}>
            <Title
              svg1={source.title.tablet.svg2}
              svg2={source.title.tablet.svg1}
              svg3={source.title.tablet.svg3}
              top1={27}
              top2={43}
            >
              <h3 className={s.h3}>{t('Contacts')}</h3>
            </Title>
          </div>
          <div className={s.team}>
            <ul className={s.linkList}>
              {data.map(item => (
                <li className={s.item} key={item.src}>
                  <div className={s.containerInfo}>
                    <img
                      src={item.src}
                      alt="personPhoto"
                      height="228"
                      width="190"
                    />
                    <div className={s.containerPerson}>
                      <p className={s.fio}>{t(item.fio)}</p>
                      <p className={s.position}>{t(item.position)}</p>
                    </div>
                    <p className={s.resposibility}>{t(item.resposibility1)}</p>
                    {item.resposibility2 && (
                      <p className={s.resposibility2}>
                        {t(item.resposibility2)}
                      </p>
                    )}
                    <a
                      className={s.linkSocial}
                      href={item.href}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p className={s.mail}>{t(item.mail)}</p>
                    </a>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <div className={s.containerWrapper}>
              <div id="history_t" className={s.linkHistory}></div>
              <Title
                svg1={source.title.tablet.svg2}
                svg2={source.title.tablet.svg1}
                svg3={source.title.tablet.svg3}
              >
                <h2 className={s.h3}>{t('History')}</h2>
              </Title>
            </div>
            <div className={s.containerWrapperHistoryTablet}>
              <div className={s.containerPosition}>
                <div className={s.pictures}>
                  <picture className={s.imgWrapper}>
                    <img
                      className={s.images}
                      src={picture}
                      alt="Production"
                      height="auto"
                      width="320"
                    />
                  </picture>
                </div>
                <div className={s.historyRightPart}>
                  <p className={s.textLineUppercaseAccent}>
                    {t('Company foundation in 2000:')}{' '}
                  </p>
                  <p
                    className={s.information}
                    onClick={() => {
                      setIsBlokVisible(!isBlokVisible);
                    }}
                  >
                    {t('more information ')}-->
                  </p>

                  <p className={s.textLineUppercase}>
                    {t('Change of location in 2006:')}
                  </p>
                  <p className={s.textLine}>
                    {t(
                      'In the summer of 2006, the company moved to Messerschmittstraße 5 in the Möhringer Vorstadt. Since then, Peuker has been manufacturing with its highly qualified specialists in a production area of 500 square meters.'
                    )}
                    <br />
                  </p>

                  <p className={s.textLineUppercase}>
                    {t('Change of name in 2019:')}
                  </p>
                  <span className={s.textLineTop}>
                    {t(
                      'With the recording in the register, the sole proprietorship Peuker Fräs - und Zerspanungstechnik e.K. been transferred to'
                    )}
                  </span>
                  <span className={s.textLineAccent}>
                    {t('Peuker - und Zerspanungstechnik GmbH & Co. KG.')}
                  </span>

                  <p className={s.textLineUppercase}>
                    {t('Succession plan in 2022:')}
                  </p>
                  <p className={s.textLine}>
                    {t(
                      'In the course of the succession Helmut Peuker decided to hand over the company to experienced hands and PFZ was taken over by long-time existing employees in order to be able to continue to guarantee a smooth handling for our customers.'
                    )}
                  </p>
                </div>
              </div>
              <div className={s.wrapperTextTablet}></div>
            </div>
          </div>
        </div>
      </div>

      <div className={s.containerScrollMobile}>
        <div
          className={
            i18n.language === 'de' ? s.containerMobile : s.containerMobileEn
          }
        ></div>
        <div id="user_m" className={s.teamLink}></div>
        <div className={s.wrapper}>
          <div className={s.teamManagement}>
            <div className={s.containerWrapper}>
              <div className={s.containerWrapper}>
                <Title
                  svg1={source.title.mobile.svg1}
                  svg2={source.title.mobile.svg2}
                  svg3={source.title.mobile.svg3}
                  top1={17}
                  top2={33}
                >
                  <h3 className={s.h3}>{t('Our Team')}</h3>
                </Title>
              </div>
            </div>
            <div id="team_m" className={s.teamLinkAccent}></div>
            <div className={s.wrapperHistory}>
              <div
                className={
                  i18n.language === 'en' ? s.teamRightPart : s.teamRightPartDe
                }
              >
                <p className={s.imgText}>
                  {t('Teamwork - We take responsibility together')}
                </p>
                <div className={s.pictures}>
                  <picture className={s.imgWrapper}>
                    <img
                      className={s.images}
                      src={picture}
                      alt="Production"
                      height="221"
                      width="331"
                    />
                  </picture>
                </div>
                <div className={s.text}>
                  <p className={s.textLine}>
                    {t(
                      'Behind every business success is a great team. And PFZ GmbH & Co. KG is no exception. Many employees have been working for the company for more than 10 years. The team has a culture of "family feeling". Working with competent employees whom the company respects and trusts means that ideas and plans move further, faster and more effectively.'
                    )}
                  </p>
                </div>
              </div>
              <div className={s.teamLeftPart}>
                <p className={s.imgText}>{t('Production area')}</p>
                <div className={s.pictures}>
                  <picture className={s.imgWrapper}>
                    <img
                      className={s.images}
                      src={picture4}
                      alt="our team"
                      height="221"
                      width="331"
                    />
                  </picture>
                </div>

                <div className={s.text}>
                  <p className={s.textLine}>
                    {t(
                      'We specialize in milling, turning, and laser cutting on modern machining centers. In two-shift operations, we produce components for medical technology, mechanical engineering, aerospace, and the jewelry industry. We also machine materials that are unusually difficult to machine, such as titanium, platinum, corrosion-resistant steel or high-strength plastics.'
                    )}
                  </p>
                  <p className={s.textLine}>
                    {t(
                      'Small and medium series of 25 to 300 parts are our strength, but larger series can also be produced off the shelf on our machining center. The production of prototypes is one of our daily challenges.'
                    )}
                  </p>
                  <p className={s.textLine}>
                    {t(
                      'In order to achieve the productivity required for cost-effective production, we work exclusively on machining centers from Chiron.'
                    )}
                  </p>
                  <p className={s.textLine}>
                    {t(
                      'In order to ensure almost one hundred percent process reliability, our drilling and milling tools are automatically checked again and again for wear and breakage.'
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={s.containerWrapper}>
            <Title
              svg1={source.title.mobile.svg1}
              svg2={source.title.mobile.svg2}
              svg3={source.title.mobile.svg3}
              top1={17}
              top2={32}
            >
              <h3 className={s.h3}>{t('Contacts')}</h3>
            </Title>
          </div>
          <div className={s.team}>
            <ul className={s.linkList}>
              {data.map(item => (
                <li className={s.item} key={item.src}>
                  <div className={s.containerInfo}>
                    <img
                      src={item.src}
                      alt="personPhoto"
                      height="312"
                      width="270"
                    />
                    <div className={s.containerPerson}>
                      <p className={s.fio}>{t(item.fio)}</p>
                      <p className={s.position}>{t(item.position)}</p>
                    </div>
                    <p className={s.resposibility}>{t(item.resposibility1)}</p>
                    {item.resposibility2 && (
                      <p className={s.resposibility2}>
                        {t(item.resposibility2)}
                      </p>
                    )}
                    <a
                      className={s.linkSocial}
                      href={item.href}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p className={s.mail}>{t(item.mail)}</p>
                    </a>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <div className={s.containerWrapper}>
              <div id="history_m" className={s.linkHistory}></div>

              <Title
                svg1={source.title.mobile.svg1}
                svg2={source.title.mobile.svg2}
                svg3={source.title.mobile.svg3}
                top1={18}
                top2={33}
              >
                <h3 className={s.h3}>{t('History')}</h3>
              </Title>
            </div>
            <div className={s.containerPosition}>
              <div className={s.historyLeftPart}>
                <picture className={s.imgWrapper}>
                  <img
                    className={s.images}
                    src={picture}
                    alt="Production"
                    height="auto"
                    width="331"
                  />
                </picture>

                <div className={s.historyRightPart}>
                  <p className={s.textLineUppercaseAccent}>
                    {t('Company foundation in 2000:')}{' '}
                  </p>
                  <p
                    className={s.information}
                    onClick={() => {
                      setIsBlokVisible(!isBlokVisible);
                    }}
                  >
                    {t('more information')}-->
                  </p>

                  <p className={s.textLineUppercase}>
                    {t('Change of location in 2006:')}
                  </p>
                  <p className={s.textLine}>
                    {t(
                      'In the summer of 2006, the company moved to Messerschmittstraße 5 in the Möhringer Vorstadt. Since then, Peuker has been manufacturing with its highly qualified specialists in a production area of 500 square meters.'
                    )}
                    <br />
                  </p>

                  <p className={s.textLineUppercase}>
                    {t('Change of name in 2019:')}
                  </p>
                  <span className={s.textLineTop}>
                    {t(
                      'With the recording in the register, the sole proprietorship Peuker Fräs - und Zerspanungstechnik e.K. been transferred to'
                    )}
                  </span>
                  <span className={s.textLineAccent}>
                    {t('Peuker - und Zerspanungstechnik GmbH & Co. KG.')}
                  </span>

                  <p className={s.textLineUppercase}>
                    {t('Succession plan in 2022:')}
                  </p>
                  <p className={s.textLine}>
                    {t(
                      'In the course of the succession Helmut Peuker decided to hand over the company to experienced hands and PFZ was taken over by long-time existing employees in order to be able to continue to guarantee a smooth handling for our customers.'
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className={s.containerPosition}></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Team;
