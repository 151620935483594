import react from 'react';
import s from './careerDescription.module.scss';
import data from '../career/constants/list.json';
import Title from 'components/molecules/title/Title';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Icon from 'components/atoms/icon';
import Footer from 'components/footer';
import Marker from 'components/atoms/marker/Marker';
import { ReactComponent as LeftTopSVG } from '../../images/orderForm/leftTop.svg';
import { ReactComponent as LeftBottomSVG } from '../../images/orderForm/leftBottom.svg';
import { ReactComponent as RightTopSVG } from '../../images/orderForm/rightTop.svg';
import { ReactComponent as RightBottomSVG } from '../../images/orderForm/rightBottom.svg';

const CareerDescription = ({ isOpenMenu }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const position = data.find(
    item => item.id === location.search.slice(1, location.search.length)
  );
  const source = {
    title: {
      desktop: {
        svg1: 'policy-desktop-titlevector3',
        svg2: 'policy-desktop-titlevector2',
        svg3: 'policy-desktop-titlevector1',
      },
      tablet: {
        svg1: 'policy-tablet-titlevector2',
        svg2: 'policy-tablet-titlevector1',
        svg3: 'policy-tablet-titlevector3',
      },
      mobile: {
        svg1: 'policy-mobile-titlevector2',
        svg2: 'policy-mobile-titlevector3',
        svg3: 'policy-mobile-titlevector1',
      },
    },
  };
  return (
    <div style={{ filter: isOpenMenu && 'blur(10px)' }}>
      <div className={s.container}>
        <LeftTopSVG className={s.leftTopSvg} />
        <LeftBottomSVG className={s.leftBottomSvg} />
        <RightTopSVG className={s.rightTopSvg} />
        <RightBottomSVG className={s.rightBottomSvg} />
        <Link className={s.link} to="/career">
          <Icon name="close-menu" size={40} className={s.iconClose}></Icon>
        </Link>
        <div className={s.wrapper}>
          <div className={s.wrapperContainer}>
            <Title
              svg1={source.title.desktop.svg2}
              svg2={source.title.desktop.svg1}
              svg3={source.title.desktop.svg3}
              animate={true}
            >
              <h2 className={s.title}>{t('JOB DESCRIPTION')}</h2>
            </Title>
          </div>
          <h2 className={s.titleAccent}>{t(position.title2)}</h2>
          <div className={s.commonContainer}>
            <div className={s.commonContainerLeft}>
              <div className={s.containerContactsLeft}>
                <p className={s.contactsDescriptionAccent}>{t('Your tasks')}</p>
                <ul className={s.list}>
                  {position.arrayTask.map((item, i) => (
                    <li className={s.containerItemAccent} key={item}>
                      <div className={s.containerMarker}>
                        <Marker ind={i} />
                      </div>
                      <div className={s.contactsText}>{t(item)}</div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={s.containerContactsLeft}>
                <p className={s.contactsDescriptionAccent}>
                  {t('Your profile')}
                </p>
                <ul className={s.list}>
                  {position.arrayProfile.map((item, i) => (
                    <li className={s.containerItemAccent} key={item}>
                      <div className={s.containerMarker}>
                        <Marker ind={i} />
                      </div>
                      <div className={s.contactsText}>{t(item)}</div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={s.containerContactsLeft}>
                <p className={s.contactsDescriptionAccent}>
                  {t('What we offer')}
                </p>
                <ul className={s.list}>
                  {position.arraySuggest.map((item, i) => (
                    <li className={s.containerItemAccent} key={item}>
                      <div className={s.containerMarker}>
                        <Marker ind={i} />
                      </div>
                      <div className={s.contactsText}>{t(item)}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className={s.commonContainerRight}>
              <div className={s.containerContacts}>
                <p className={s.contactsDescription}>
                  {t('Application by email')}
                </p>{' '}
                <p className={s.contactsText}>
                  {t('Would you like to apply?')}
                </p>{' '}
                <p className={s.contactsText}>
                  {t(
                    'Send your detailed application documents including your earliest starting date by e-mail to'
                  )}
                </p>
                <a
                  className={s.linkSocial}
                  href="mailto: sascha.maluscik@pfz-cnc.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className={s.contactsTextMail}>
                    sascha.maluscik@pfz-cnc.com
                  </p>
                </a>
              </div>
              <div className={s.containerContacts}>
                <p className={s.contactsDescription}>
                  {' '}
                  {t('Job type & Timing')}
                </p>{' '}
                <p className={s.contactsText}>
                  {t('This is a full-time position.')}
                </p>{' '}
                <p className={s.contactsText}>
                  {t('We are looking for reinforcement as soon as possible.')}
                </p>{' '}
              </div>
              <div className={s.containerContacts}>
                <p className={s.contactsDescription}>{t('Contact1')}</p>{' '}
                <p className={s.contactsText}>{t('Personnel department')}</p>{' '}
                <a href="tel: +4974619662999">
                  <div className={s.numberTel}>
                    <p className={s.contactsText}>Tel: +49 (0) 7461/ 9662999</p>
                  </div>
                </a>
                <a
                  className={s.linkSocial}
                  href="mailto: sascha.maluscik@pfz-cnc.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className={s.contactsTextTitle}>{t('Email1')}</p>
                  <p className={s.contactsTextMail}>
                    sascha.maluscik@pfz-cnc.com
                  </p>
                </a>
                <p className={s.contactsTextBottom}>{t('Germany')}</p>
              </div>
              <div className={s.containerContacts}>
                <p className={s.contactsDescription}>{t('Location')}</p>{' '}
                <p className={s.contactsText}>PFZ Peuker GmbH & Co. KG</p>{' '}
                <p className={s.contactsText}>
                  Messerschmittstrasse 5, 78532 Tuttlingen
                </p>{' '}
                <p className={s.contactsTextBottom}>{t('Germany')}</p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <div className={s.containerTablet}>
        <Link className={s.link} to="/career">
          <Icon name="close-menu" size={40} className={s.iconClose}></Icon>
        </Link>
        <div className={s.wrapper}>
          <div className={s.wrapperContainer}>
            <Title
              svg1={source.title.tablet.svg2}
              svg2={source.title.tablet.svg1}
              svg3={source.title.tablet.svg3}
              animate={true}
            >
              <h2 className={s.title}>{t('JOB DESCRIPTION')}</h2>
            </Title>
          </div>
          <h2 className={s.titleAccent}>{t(position.title2)}</h2>
          <div className={s.commonContainer}>
            <div className={s.commonContainerLeft}>
              <div className={s.containerContactsLeft}>
                <p className={s.contactsDescriptionAccent}>{t('Your tasks')}</p>
                <ul className={s.list}>
                  {position.arrayTask.map((item, i) => (
                    <li className={s.containerItemAccent} key={item}>
                      <div className={s.containerMarker}>
                        <Marker ind={i} />
                      </div>
                      <div className={s.contactsText}>{t(item)}</div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={s.containerContactsLeft}>
                <p className={s.contactsDescriptionAccent}>
                  {t('Your profile')}
                </p>
                <ul className={s.list}>
                  {position.arrayProfile.map((item, i) => (
                    <li className={s.containerItemAccent} key={item}>
                      <div className={s.containerMarker}>
                        <Marker ind={i} />
                      </div>
                      <div className={s.contactsText}>{t(item)}</div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={s.containerContactsLeft}>
                <p className={s.contactsDescriptionAccent}>
                  {' '}
                  {t('What we offer')}
                </p>
                <ul className={s.list}>
                  {position.arraySuggest.map((item, i) => (
                    <li className={s.containerItemAccent} key={item}>
                      <div className={s.containerMarker}>
                        <Marker ind={i} />
                      </div>
                      <div className={s.contactsText}>{t(item)}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className={s.commonContainerRight}>
              <div className={s.containerContacts}>
                <p className={s.contactsDescription}>
                  {t('Application by email')}
                </p>{' '}
                <p className={s.contactsTextBottom}>
                  {t('Would you like to apply?')}
                </p>{' '}
                <p className={s.contactsTextBottom}>
                  {t(
                    'Send your detailed application documents including your earliest starting date by e-mail to'
                  )}
                </p>
                <a
                  className={s.linkSocial}
                  href="mailto: sascha.maluscik@pfz-cnc.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className={s.contactsTextBottomMail}>
                    sascha.maluscik@pfz-cnc.com
                  </p>
                </a>
              </div>

              <div className={s.containerContacts}>
                <p className={s.contactsDescription}>
                  {t('Job type & Timing')}
                </p>{' '}
                <p className={s.contactsTextBottom}>
                  {t('This is a full-time position.')}
                </p>{' '}
                <p className={s.contactsTextBottom}>
                  {t('We are looking for reinforcement as soon as possible.')}
                </p>{' '}
              </div>

              <div className={s.containerContacts}>
                <p className={s.contactsDescription}>{t('Contact1')}</p>{' '}
                <p className={s.contactsTextBottom}>
                  {t('Personnel department')}
                </p>{' '}
                <a href="tel: +4974619662999">
                  <div className={s.numberTel}>
                    <p className={s.contactsTextBottom}>
                      Tel: +49 (0) 7461/ 9662999
                    </p>
                  </div>
                </a>
                <a
                  className={s.linkSocial}
                  href="mailto: sascha.maluscik@pfz-cnc.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className={s.contactsTextTitle}>{t('Email1')}</p>
                  <p className={s.contactsTextBottomMail}>
                    sascha.maluscik@pfz-cnc.com
                  </p>
                </a>
                <p className={s.contactsTextBottom}>{t('Germany')}</p>
              </div>

              <div className={s.containerContacts}>
                <p className={s.contactsDescription}>{t('Location')}</p>{' '}
                <p className={s.contactsTextBottom}>PFZ Peuker GmbH & Co. KG</p>{' '}
                <p className={s.contactsTextBottom}>
                  Messerschmittstrasse 5, 78532 Tuttlingen
                </p>{' '}
                <p className={s.contactsTextBottom}>{t('Germany')}</p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <div className={s.containerMobile}>
        <Link className={s.link} to="/career">
          <Icon name="close-menu" size={40} className={s.iconClose}></Icon>
        </Link>
        <div className={s.wrapper}>
          <div className={s.wrapperContainer}>
            <Title
              svg1={source.title.mobile.svg2}
              svg2={source.title.mobile.svg1}
              svg3={source.title.mobile.svg3}
              animate={true}
              top2={32}
              top1={18}
            >
              <h2 className={s.title}>{t('JOB DESCRIPTION')}</h2>
            </Title>
          </div>
          <h2 className={s.titleAccent}>{t(position.title2)}</h2>
          <div className={s.commonContainer}>
            <div className={s.commonContainerLeft}>
              <div className={s.containerContactsLeft}>
                <p className={s.contactsDescriptionAccent}>{t('Your tasks')}</p>
                <ul className={s.list}>
                  {position.arrayTask.map((item, i) => (
                    <li className={s.containerItemAccent} key={item}>
                      <div className={s.containerMarker}>
                        <Marker ind={i} />
                      </div>
                      <div className={s.contactsText}>{t(item)}</div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={s.containerContactsLeft}>
                <p className={s.contactsDescriptionAccent}>
                  {t('Your profile')}
                </p>
                <ul className={s.list}>
                  {position.arrayProfile.map((item, i) => (
                    <li className={s.containerItemAccent} key={item}>
                      <div className={s.containerMarker}>
                        <Marker ind={i} />
                      </div>
                      <div className={s.contactsText}>{t(item)}</div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={s.containerContactsLeft}>
                <p className={s.contactsDescriptionAccent}>
                  {' '}
                  {t('What we offer')}
                </p>
                <ul className={s.list}>
                  {position.arraySuggest.map((item, i) => (
                    <li className={s.containerItemAccent} key={item}>
                      <div className={s.containerMarker}>
                        <Marker ind={i} />
                      </div>
                      <div className={s.contactsText}>{t(item)}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className={s.commonContainerRight}>
              <div className={s.containerContacts}>
                <p className={s.contactsDescription}>
                  {' '}
                  {t('Application by email')}
                </p>{' '}
                <p className={s.contactsTextBottom}>
                  {t('Would you like to apply?')}
                </p>{' '}
                <p className={s.contactsTextBottom}>
                  {t(
                    'Send your detailed application documents including your earliest starting date by e-mail to'
                  )}
                </p>
                <a
                  className={s.linkSocial}
                  href="mailto: sascha.maluscik@pfz-cnc.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className={s.contactsTextBottomMail}>
                    sascha.maluscik@pfz-cnc.com
                  </p>
                </a>
              </div>

              <div className={s.containerContacts}>
                <p className={s.contactsDescription}>
                  {' '}
                  {t('Job type & Timing')}
                </p>{' '}
                <p className={s.contactsTextBottom}>
                  {t('This is a full-time position.')}
                </p>{' '}
                <p className={s.contactsTextBottom}>
                  {t('We are looking for reinforcement as soon as possible.')}
                </p>{' '}
              </div>

              <div className={s.containerContacts}>
                <p className={s.contactsDescription}>{t('Contact1')}</p>{' '}
                <p className={s.contactsTextBottom}>
                  {t('Personnel department')}
                </p>{' '}
                <a href="tel: +4974619662999">
                  <div className={s.numberTel}>
                    <p className={s.contactsTextBottom}>
                      Tel: +49 (0) 7461/ 9662999
                    </p>
                  </div>
                </a>
                <a
                  className={s.linkSocial}
                  href="mailto: sascha.maluscik@pfz-cnc.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className={s.contactsTextTitle}>{t('Email1')}</p>
                  <p className={s.contactsTextBottomMail}>
                    sascha.maluscik@pfz-cnc.com
                  </p>
                </a>
                <p className={s.contactsTextBottom}>{t('Germany')}</p>
              </div>

              <div className={s.containerContacts}>
                <p className={s.contactsDescription}>{t('Location')}</p>{' '}
                <p className={s.contactsTextBottom}>PFZ Peuker GmbH & Co. KG</p>{' '}
                <p className={s.contactsTextBottom}>
                  Messerschmittstrasse 5, 78532 Tuttlingen
                </p>{' '}
                <p className={s.contactsTextBottom}>{t('Germany')}</p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default CareerDescription;
