import React, { useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import OrderForm from './orderForm';
import Layout from './layout';
import { MainPage } from './layout/mainPage';
import { Policy } from './layout/policy';
import { CareerPage } from './layout/careerPage';
import { CareerDescriptionPage } from './layout/careerDescriptionPage';

export const App = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isActiveCheckbox, setIsActiveCheckbox] = useState(false);
  const [isModalVisibleForm, setModalVisibleForm] = useState(false);
  const [isLogoLoaded, setIsLogoLoaded] = useState(false);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Layout
              setIsLogoLoaded={setIsLogoLoaded}
              isOpenMenu={isOpenMenu}
              setIsOpenMenu={setIsOpenMenu}
            />
          }
        >
          <Route
            index
            element={
              <MainPage
                isOpenMenu={isOpenMenu}
                isActiveCheckbox={isActiveCheckbox}
                setIsActiveCheckbox={setIsActiveCheckbox}
                isModalVisibleForm={isModalVisibleForm}
                setModalVisibleForm={setModalVisibleForm}
                isLogoLoaded={isLogoLoaded}
              />
            }
          />
          <Route
            path="policy"
            element={
              <Policy
                isActiveCheckbox={isActiveCheckbox}
                setIsActiveCheckbox={setIsActiveCheckbox}
              />
            }
          />
          <Route
            path="order-form"
            element={
              <>
                <OrderForm
                  setIsActiveCheckbox={setIsActiveCheckbox}
                  isActiveCheckbox={isActiveCheckbox}
                  setModalVisibleForm={setModalVisibleForm}
                />
              </>
            }
          />
          <Route
            path="career"
            element={<CareerPage isOpenMenu={isOpenMenu} />}
          />
          <Route
            path="career-description"
            element={<CareerDescriptionPage isOpenMenu={isOpenMenu} />}
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </>
  );
};
