import Footer from '../footer';
import BackToBottomButton from '../backToBottomButton/backToBottomButton';

import Career from 'components/career/career';

export const CareerPage = ({ isOpenMenu }) => (
  <>
    <Career isOpenMenu={isOpenMenu} />
    <BackToBottomButton />
  </>
);
// commit
